import { DateTime } from "luxon";

const el = document.getElementById("clockWidget");
if (el) {
  setTime(el);
  setInterval(() => setTime(el), 1000);
}

const counter = document.getElementById("start-counter");
if (counter) {
  setInterval(() => updateCounter(counter), 1000 * 60);
}

function setTime(el: HTMLElement) {
  setLocalTime(el);
}

function setLocalTime(el: HTMLElement) {
  const timezone = el.dataset["timezone"];
  const time = DateTime.now().setZone(timezone);
  const timeLocal = time.toLocaleString(DateTime.TIME_SIMPLE);
  const timezoneAbbr = time.toFormat("ZZZZ");
  el.innerHTML = `${timeLocal} ${timezoneAbbr}`;
}
function setUserTime(el: HTMLElement) {
  const time = DateTime.now();
  const timeLocal = time.toLocaleString(DateTime.TIME_SIMPLE);
  const timezoneAbbr = time.toFormat("ZZZZ");
  el.innerHTML = el.innerHTML + `<br />${timeLocal} ${timezoneAbbr}`;
}

function updateCounter(el: HTMLElement) {
  const timestamp = el.dataset["timestamp"]!;
  const time = DateTime.fromSeconds(Number.parseInt(timestamp));
  const diff = DateTime.now().diff(time, ["days", "hours", "minutes"]);
  el.innerHTML = `${diff.days} days ${diff.hours} hours ${Math.floor(
    diff.minutes
  )} minutes`;
}
