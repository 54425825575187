import axios from "axios";
import TrackerMap from "./model/tracker_map";
import $ from "cash-dom";
import MapFunctions from "./map_functions";
import colors from "../colors";
import laravelEcho from "../echo";
import { GeoJSONSource, Marker, Popup } from "mapbox-gl";
import { DateTime } from "luxon";

import distLayer from "./dist-marker-layer.json";

const token = import.meta.env.VITE_MAPBOX_TOKEN || "";
const channelPrefix = import.meta.env.VITE_CHANNEL_PREFIX || "";

$(function () {
  const mapCanvas = document.getElementById("map-canvas");
  if (mapCanvas === null) return;

  const app = new App(mapCanvas);
  app.init();
});

export default class App {
  private canvas: HTMLElement;

  private adventure!: Adventure;
  private ongoing = true;
  private settings!: Settings;

  private map: TrackerMap | undefined;
  private mainDevice: TrackingDevice | undefined;

  private currentPoint: TrackingPoint | undefined;

  constructor(canvas: HTMLElement) {
    this.canvas = canvas;
  }

  async init() {
    const res = await axios.get(
      `/api/adventures/${this.canvas.dataset.adventureId}`
    );
    this.adventure = res.data;

    this.registerWeatherChannel(this.adventure.id);

    const center = this.canvas.dataset.center
      ?.split(" ")
      .map((c) => parseFloat(c)) as [number, number] | undefined;

    this.ongoing = !!this.canvas.dataset.ongoing;

    let opts = {};
    if (this.ongoing && center) {
      opts = {
        zoom: 13,
        center,
      };
    } else if (this.canvas.dataset.bbox) {
      opts = {
        bounds: JSON.parse(this.canvas.dataset.bbox || ""),
        fitBoundsOptions: {
          padding: 50,
        },
      };
    }

    this.settings = JSON.parse(this.canvas.dataset["settings"]!);
    this.map = new TrackerMap(token, opts, this.settings.units.distance);
    this.map.timezone = this.adventure.timezone;
    this.map.load(() => this.setup(this.map!));
  }

  private async setup(map: TrackerMap) {
    const virtualRunners = await this.getVirtualrunners();
    const devices = await this.getDevices();

    this.mainDevice = devices.find((dev) => dev.main_device);

    this.canvas.addEventListener("settingsupdated", (ev) => {
      this.settings.units = (ev as CustomEvent).detail as Settings["units"];
      this.activateDistanceMarkers(map, this.settings.units.distance);
    });

    this.prepare(map, virtualRunners, devices);
    this.loadData(map, virtualRunners, devices);

    document.addEventListener("reload-map", () => {
      this.resetData(map);
      this.loadData(map, virtualRunners, devices);
    });
  }

  prepare(
    map: TrackerMap,
    virtualRunners: VirtualRunner[],
    devices: TrackingDevice[]
  ) {
    const otherDevices = devices.filter((dev) => !dev.main_device);

    map.sources = [
      "main-route",
      "dist-markers-metric",
      "dist-markers-imperial",
      "virtual-runner-routes",
      "other-device-routes",
      "detour-routes",
      "main-device-route",
      "checkpoint-markers",
      "virtual-runner-markers",
      "other-device-markers",
      "main-device-markers",
    ].map((name) => ({
      name,
      source: {
        type: "geojson",
        data: MapFunctions.initGeojson(),
      },
      data: MapFunctions.initGeojson(),
    }));

    map.addSources();
    map.addMouseStates("main-device-markers");
    map.addClickHandlers("main-device-markers");

    this.setupMainRouteLayer(map);
    this.setupDetourLayer(map);
    this.setupCheckpointLayer(map);
    this.setupVRLayers(map, virtualRunners);
    this.setupOtherDeviceLayers(map, otherDevices);
    if (this.mainDevice) this.setupMainDeviceLayer();

    this.setLiveIndicator(devices);
  }

  resetData(map: TrackerMap) {
    map.trackingPoints = [];
  }

  async loadData(
    map: TrackerMap,
    virtualRunners: VirtualRunner[],
    devices: TrackingDevice[]
  ) {
    if (!this.map) return;

    this.loadMainRoute(this.map);

    const otherDevices = devices.filter((dev) => !dev.main_device);

    this.loadCheckpoints(map);

    const loadOthers = new Promise<void>((done) => {
      otherDevices.forEach(async (device) => {
        let marker: Marker | undefined;
        const tracking_points = await this.getTrackingPoints(device, 1);

        if (tracking_points.length > 0) {
          marker = map.putPhotoMarker(
            tracking_points[0].location.coordinates,
            device.name,
            device.photo,
            {
              size: "w-14 h-14",
            }
          );
          map.updatePopup(marker, tracking_points[0], device.name);
        }

        this.registerWS(`tracker.${device.id}`, map, marker);
      });

      this.mapPhotos(map)
        .then(() => this.mapVirtualRunners(map, virtualRunners))
        .then(() => done());
    });

    // TODO: enable based on settings
    // loadDetours(map);

    //map.trackingPoints = result.data;

    // load others first, then load everything else
    // this ensures correct layering
    loadOthers.then(async () => {
      if (!this.mainDevice) return;

      const route = await this.getRoute(this.mainDevice.id);

      const latestPoints = await this.getTrackingPoints(this.mainDevice);
      this.currentPoint = latestPoints[0];
      if (!this.currentPoint) return;

      const marker = map.putCurrentMarker(
        this.currentPoint,
        this.mainDevice,
        this.ongoing
      );
      map.updatePopup(marker, this.currentPoint, this.mainDevice.name);
      this.registerWS(`tracker.${this.mainDevice.id}`, map, marker);

      this.map?.drawFeatureCollection("main-device-route", route);
      this.map?.drawFeatureCollection("main-device-markers", route);
    });

    this.loadDistanceMarkers(map).then(() =>
      this.activateDistanceMarkers(map, this.settings.units.distance)
    );
    this.loadBanner(this.adventure);
  }

  async loadMainRoute(map: TrackerMap) {
    const result = await axios.get(
      `/api/adventures/${this.adventure.id}/route`
    );

    map.drawGeoJson("main-route", result.data);
  }

  async loadCheckpoints(map: TrackerMap) {
    const result = await axios.get(
      `/api/adventures/${this.adventure.id}/checkpoints`
    );
    if (!result.data) return;

    const source = map.getSource("checkpoint-markers");
    if (source) {
      source.data.features = result.data;
    }

    map.updateSource("checkpoint-markers");
    result.data.forEach((checkpoint: GeoJSON.Feature) => {
      map.putCheckpoint(checkpoint);
    });
  }

  async loadDetours(map: TrackerMap) {
    const result = await axios.get(
      `/api/adventures/${this.adventure.id}/detours`
    );

    result.data.forEach((detour: Detour) => {
      map.drawGeoJson("detour-routes", JSON.parse(detour.route));
    });
  }

  async loadDistanceMarkers(map: TrackerMap) {
    this.adventure.distance_markers_json.forEach((m: DistanceMarkerJson) => {
      (map.map.getSource(`dist-markers-${m.units}`) as GeoJSONSource).setData(
        m.url
      );
    });
  }

  activateDistanceMarkers(map: TrackerMap, units?: string) {
    units = units || "metric";
    if (units == "metric") {
      map.map.setLayoutProperty("dist-markers-metric", "visibility", "visible");
      map.map.setLayoutProperty("dist-markers-imperial", "visibility", "none");
      map.scale.setUnit("metric");
    } else {
      map.map.setLayoutProperty("dist-markers-metric", "visibility", "none");
      map.map.setLayoutProperty(
        "dist-markers-imperial",
        "visibility",
        "visible"
      );
      map.scale.setUnit("imperial");
    }
  }

  setupVRLayers(map: TrackerMap, virtualRunners: VirtualRunner[]) {
    virtualRunners.forEach((vr) => {
      map.addLayer({
        id: `vr-${vr.id}-border`,
        source: `virtual-runner-routes`,
        filter: ["==", ["id"], vr.id],
        paint: {
          "line-color": "#FFFFFF",
          // prettier-ignore
          "line-width": ["interpolate", ["linear"], ["zoom"],
          0, 5,
          10, 6,
          20, 9],
          "line-opacity": 0.5,
        },
      });

      map.addLayer({
        id: `vr-${vr.id}`,
        source: `virtual-runner-routes`,
        filter: ["==", ["id"], vr.id],
        paint: {
          "line-color": colors["pct-green"]["600"],
          // prettier-ignore
          "line-width": ["interpolate", ["linear"], ["zoom"],
          0, 1,
          10, 2,
          20, 5],
          "line-opacity": 0.5,
        },
      });
    });
  }

  setupOtherDeviceLayers(map: TrackerMap, devices: TrackingDevice[]) {
    devices.forEach((device) => {
      map.addLayer({
        id: `device-${device.id}-border`,
        source: "other-device-routes",
        filter: ["==", ["id"], device.id],
        paint: {
          "line-color": "#FFFFFF",
          // prettier-ignore
          "line-width": ["interpolate", ["linear"], ["zoom"],
          0, 5,
          10, 6,
          20, 9],
          "line-opacity": 0.7,
        },
      });

      map.addLayer({
        id: `device-${device.id}`,
        source: "other-device-routes",
        filter: ["==", ["id"], device.id],
        paint: {
          "line-color": colors["pct-olive"]["600"],
          // prettier-ignore
          "line-width": ["interpolate", ["linear"], ["zoom"],
          0, 1,
          10, 2,
          20, 5],
          "line-opacity": 0.7,
        },
      });
    });
  }

  setupCheckpointLayer(map: TrackerMap) {
    map.map.addLayer({
      id: "checkpoint-markers",
      type: "symbol",
      source: "checkpoint-markers",
      layout: {
        "icon-image": "flag-fill",
        // prettier-ignore
        "icon-size": ["interpolate", ["linear"], ["zoom"],
          0, 0.1,
          15, 0.8,
          20, 1],
        "icon-anchor": "bottom-left",
      },
      paint: {
        "icon-opacity": 0,
      },
    });

    const popup = new Popup({
      closeButton: false,
      anchor: "bottom",
      offset: [0, -5],
    });

    map.map.on("mouseenter", "checkpoint-markers", () => {
      map.map.getCanvas().style.cursor = "pointer";
    });

    map.map.on("click", "checkpoint-markers", (e) => {
      if (e && e.features) {
        const pt = e.features[0].geometry as GeoJSON.Point;
        const coordinates = pt.coordinates.slice();
        const description = e.features[0].properties?.name;
        popup
          .setLngLat(coordinates as [number, number])
          .setHTML(description)
          .addTo(map.map);
      }
    });

    map.map.on("mouseleave", "checkpoint-markers", () => {
      map.map.getCanvas().style.cursor = "";
    });
  }

  setupMainDeviceLayer() {
    if (!this.map) return;

    this.map.addLayer({
      id: `main-device-border`,
      source: "main-device-route",
      paint: {
        "line-color": "#FFFFFF",
        // prettier-ignore
        "line-width": ["interpolate", ["linear"], ["zoom"],
        0, 5,
        10, 6,
        20, 9],
        "line-opacity": 0.9,
      },
    });

    this.map.addLayer({
      id: `main-device`,
      source: "main-device-route",
      paint: {
        "line-color": ['coalesce', ['get', 'color'], colors["pct-red"]["600"]],
        // prettier-ignore
        "line-width": ["interpolate", ["linear"], ["zoom"],
        0, 1,
        10, 2,
        20, 5],
        "line-opacity": 1,
      },
    });

    this.map.map.addLayer({
      id: "main-device-markers",
      type: "circle",
      source: "main-device-markers",
      // prettier-ignore
      filter: [
        'case',
        ['!=', ['geometry-type'], 'Point'], false,
        ['>=', ['zoom'], 12], true,
        ['>=', ['zoom'], 10], ['==', ['%', ["get", "id"], 5], 0],
        ['>=', ['zoom'], 7], ['==', ['%', ["get", "id"], 20], 0],
        ['>=', ['zoom'], 6], ['==', ['%', ["get", "id"], 50], 0],
        false
      ],
      paint: {
        "circle-color": colors["pct-green"][600],
        // prettier-ignore
        "circle-radius": ["interpolate",
        ["exponential", 0.5], ["zoom"],
        0, 1,
        10, 3,
        20, 5
      ],
        "circle-stroke-width": 1,
        "circle-stroke-color": colors["pct-pink"][600],
      },
    });
  }

  setupMainRouteLayer(map: TrackerMap) {
    map.addLayer({
      id: `main-route-border`,
      source: "main-route",
      paint: {
        "line-color": colors["orange"]["600"],
        // prettier-ignore
        "line-opacity": [
        "interpolate",
        ["exponential", 0.86],
        ["zoom"],
        0,0,
        10,0.6,
        22,0.6,
      ],
        // prettier-ignore
        "line-width": [
        "interpolate",
        ["exponential", 1.5],
        ["zoom"],
        13,5,
        14,5,
        15,5,
        18,15,
      ],
      },
    });

    map.addLayer({
      id: `main-route`,
      source: "main-route",
      paint: {
        "line-color": "black",
        // prettier-ignore
        "line-width": [
        "interpolate",
        ["exponential", 1.5],
        ["zoom"],
        13,2.5,
        14,3.5,
        15,3.5,
        18,8
      ],
        "line-opacity": 1,
        "line-dasharray": [
          "step",
          ["zoom"],
          ["literal", [1]],
          10,
          ["literal", [4, 3]],
          22,
          ["literal", [5, 3]],
        ],
      },
    });

    map.map.addLayer({
      id: "dist-markers-metric",
      source: "dist-markers-metric",
      ...distLayer,
    });

    map.map.addLayer({
      id: "dist-markers-imperial",
      source: "dist-markers-imperial",
      ...distLayer,
    });

    if (this.adventure.settings.arrows) {
      map.addArrowLayer("main-route");
    }
  }

  setupDetourLayer(map: TrackerMap) {
    map.addLayer({
      id: `detour-border`,
      source: "detour-routes",
      paint: {
        "line-color": colors["pct-green"][600],
        // prettier-ignore
        "line-width": ["interpolate", ["linear"], ["zoom"],
        0, 3,
        10, 6,
        20, 9],
        "line-opacity": 0.7,
      },
    });

    map.addLayer({
      id: `detour`,
      source: "detour-routes",
      paint: {
        "line-color": colors["pct-pink"]["600"],
        // prettier-ignore
        "line-width": ["interpolate", ["linear"], ["zoom"],
        0, 1,
        10, 2,
        20, 5],
        "line-opacity": 1,
        "line-dasharray": [2, 3],
      },
    });
  }

  async mapPhotos(map: TrackerMap) {
    const result: any = await axios.get(
      `/api/adventures/${this.adventure.id}/photos`
    );
    result.data.forEach((photo: Photo) => {
      map.putPhoto(photo);
    });
  }

  async mapVirtualRunners(map: TrackerMap, virtualRunners: VirtualRunner[]) {
    const source = map.getSource("virtual-runner-routes");
    if (!source) return;

    virtualRunners.forEach((virtualRunner: VirtualRunner) => {
      let coords = virtualRunner.routes.map((l) => l.location.coordinates);
      if (coords.length === 0 && virtualRunner.oldestRoute) {
        coords = [virtualRunner.oldestRoute?.location.coordinates];
      }

      if (virtualRunner.photo) {
        let description = `<strong class="block mb-2">${virtualRunner.name}</strong>`;
        description += `<p class="whitespace-pre-line">${
          virtualRunner.description || ""
        }</p>`;

        const marker = map.putPhotoMarker(
          coords[0],
          description,
          virtualRunner.photo,
          {
            size: "w-12 h-12",
            style: "grayscale !border-2",
            tag: `${virtualRunner.year}`,
          }
        );
        this.registerVRWS(`virtual-runner.${virtualRunner.id}`, map, marker);
      }
    });
    map.updateSource("virtual-runner-routes");
  }

  async getDevices(withPoints = false): Promise<TrackingDevice[]> {
    let result;

    if (withPoints) {
      result = await axios.get("/api/tracking-points");
    } else {
      result = await axios.get(
        `/api/adventures/${this.adventure.id}/tracking-devices`
      );
    }

    return result.data;
  }

  async getVirtualrunners(): Promise<VirtualRunner[]> {
    const result = await axios.get(
      `/api/adventures/${this.adventure.id}/virtual-runners`
    );

    return result.data;
  }

  async getTrackingPoints(
    device: TrackingDevice,
    count?: number
  ): Promise<TrackingPoint[]> {
    let url = `/api/tracking-devices/${device.id}/tracking-points`;
    if (count) {
      url += `?count=${count}`;
    }
    const result = await axios.get(url);

    return result.data;
  }

  async setLiveIndicator(devices: TrackingDevice[]) {
    const liveDevices = devices.filter((device) => device.live_link);

    liveDevices.forEach((device) => {
      const div = document.createElement("div");
      div.innerHTML = `${device.name} is live!<br />`;
      div.innerHTML += `Check it out <a class="underline" target="_blank" href="${device.live_link}">here</a>`;
      const indicator = document.getElementById("live-indicator");
      if (indicator) {
        const span = indicator.querySelector("span");
        if (span) {
          span.innerHTML = "";
          span?.appendChild(div);
        }
        if (indicator.classList.contains("hidden")) {
          indicator.querySelector("button")?.click();
        }
      }
    });
  }

  async removeBanner() {
    const indicator = document.getElementById("live-indicator");

    if (indicator) {
      const span = indicator.querySelector("span");
      if (span) span.innerHTML = "";
      if (!indicator.classList.contains("hidden"))
        indicator.querySelector("button")?.click();
    }
  }

  async loadBanner(adventure: Adventure) {
    const res = await axios.get(`/api/adventures/${adventure.id}/banner`);

    if (res.data) {
      setBanner(res.data);
    }
  }

  async setBanner(text: string) {
    const div = document.createElement("div");
    div.innerHTML = text;
    const indicator = document.getElementById("live-indicator");

    if (indicator) {
      const span = indicator.querySelector("span");
      if (span) {
        span.innerHTML = "";
        span?.appendChild(div);
      }
      if (indicator.classList.contains("hidden")) {
        indicator.querySelector("button")?.click();
      }
    }
  }

  registerWS(channel: string, map: TrackerMap, marker?: Marker) {
    laravelEcho
      .channel(`${channelPrefix}.${channel}`)
      .listen("TrackingPointAdded", (e: any) => {
        const trackingPoint: TrackingPoint = e.trackingPoint;

        if (e.isMainDevice) {
          if (
            this.currentPoint &&
            DateTime.fromISO(trackingPoint.timestamp) <
              DateTime.fromISO(this.currentPoint.timestamp)
          ) {
            console.log("Discarding because new point earlier than last point");
            return;
          }

          map.putMarker("main-device-markers", trackingPoint);

          if (this.ongoing) {
            map.addFeature(
              "main-device-markers",
              MapFunctions.featureFromPoint(trackingPoint)
            );

            if (this.currentPoint) {
              map.addFeature(
                "main-device-route",
                MapFunctions.geoJsonFromCoords([
                  this.currentPoint.location.coordinates,
                  trackingPoint.location.coordinates,
                ])
              );
            }
          }
          if (map.isFocused()) map.panTo(trackingPoint.location.coordinates);

          this.currentPoint = trackingPoint;
        } else {
          map.putMarker("other-device-markers", trackingPoint);
        }

        if (marker) {
          map.moveMarker(marker, trackingPoint);
          map.updatePopup(marker, trackingPoint, e.name);
        }
      });

    laravelEcho
      .channel(`${channelPrefix}.${channel}`)
      .listen("LiveLinkAdded", (e: any) => {
        const device: TrackingDevice = e.device;

        setLiveIndicator([device]);
      });

    laravelEcho
      .channel(`${channelPrefix}.${channel}`)
      .listen("LiveLinkRemoved", () => {
        removeBanner();
      });
  }

  registerVRWS(channel: string, map: TrackerMap, marker?: Marker) {
    laravelEcho
      .channel(`${channelPrefix}.${channel}`)
      .listen("VirtualRunnerUpdated", (e: any) => {
        const point: Point = e.virtualRoute;

        if (marker) map.moveMarker(marker, point);
      });
  }

  registerWeatherChannel(adventureId: number) {
    laravelEcho
      .channel(`${channelPrefix}.weather.${adventureId}`)
      .listen("WeatherUpdated", (e: any) => {
        const weather = e.weather;
        const el = document.getElementById("weather");

        if (el && weather && e.units === settings.units.temperature) {
          const temp = Math.round(weather["current"]["temp"]);
          const unit = e.units == "metric" ? "C" : "F";
          el.innerHTML = `${temp} º${unit}`;
        }
      });

    laravelEcho
      .channel(`${channelPrefix}.banner.${adventureId}`)
      .listen("BannerAdded", (e: any) => {
        setBanner(e.text);
      });

    laravelEcho
      .channel(`${channelPrefix}.banner.${adventureId}`)
      .listen("BannerRemoved", () => {
        removeBanner();
      });
  }

  private async getRoute(trackingDeviceId: number) {
    const res = await axios.get(
      `/api/v2/tracking-devices/${trackingDeviceId}/route`
    );

    return res.data;
  }
}
